
import { Component, Mixins } from 'vue-property-decorator';
import { AuthMixin, MultiStepFormMixin, VuetifyMixin, AppHostnameMixin } from '../../mixins';
import { OnboardingUserInfoFormValue, OnboardingLocationFormValue, OnboardingCoachSportFormValue, OnboardingPictureUploadFormValue } from '@/../types/interfaces';
import { SportName } from '@/../types/enums';
import { navigationStore, userStore } from '../../store';

import Page from '../Page.vue';
import Navbar from '../../components/navigation/Navbar.vue';
import MultiStepForm from '../../components/forms/MultiStepForm.vue';
import OnboardingUserInfo from '../../components/onboarding/OnboardingUserInfo.vue';
import OnboardingLocation from '../../components/onboarding/OnboardingLocation.vue';
import OnboardingCoachSport from '../../components/onboarding/coach/OnboardingCoachSport.vue';
import OnboardingProfilePicture from '../../components/onboarding/OnboardingProfilePicture.vue';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel';
import { setGAUserProperties } from '@/plugins/firebase';

@Component({
	components: {
		Page,
		Navbar,
		MultiStepForm,
		OnboardingUserInfo,
		OnboardingLocation,
		OnboardingCoachSport,
		OnboardingProfilePicture,
	},
	async beforeRouteEnter(to, from, next){
		if(userStore.HasCoachProfile === true){
			return next((vm: CoachOnboarding) => {
				return vm.openCoachApp();
			});
		}
		return next();
	}
})
export default class CoachOnboarding extends Mixins(AuthMixin, AppHostnameMixin, MultiStepFormMixin, VuetifyMixin) {
	loading: boolean = false;
	steps: number = 4;
	currentStep = 1;
	formValue: {
		step1: OnboardingUserInfoFormValue,
		step2: OnboardingLocationFormValue,
		step3: OnboardingCoachSportFormValue,
		step4: OnboardingPictureUploadFormValue,
	} = {
		step1: {
			valid: false,
			firstName: "",
			lastName: "",
			email: "",
			role: "",
		},
		step2: {
			valid: false,
			country: "",
			fromOrganization: "",
		},
		step3: {
			valid: false,
			sport: "",
			tosAgreed: false,
		},
		step4: {
			valid: false,
			pictureUrl: "",
		},
	};

	get FormTitleText(){
		switch (this.currentStep) {
		case 2: return "Nice, you're almost done.";
		case 3: return "Last step and you're all set!";
		case 4: return "Ok, your registration is complete!";
		default:
			return "Let's get you set up!";
		}
	}
	get FormSubTitleText(){
		switch (this.currentStep) {
		case 2: return "Please let us know where you are located";
		case 3: return "Please let us know which sport you coach";
		case 4: return "You can provide an optional profile photo.";
		default:
			return "Answer a few questions to get started";
		}
	}

	get Loading(){
		return userStore.addCoachProfileLoading;
	}

	async created () {
		// Name may or may not be present depending on signup method
		if(this.$auth.auth0User.given_name) this.formValue.step1.firstName = this.$auth.auth0User.given_name;
		if(this.$auth.auth0User.family_name) this.formValue.step1.lastName = this.$auth.auth0User.family_name;
		// Email should always be available
		if(this.$auth.auth0User.email) this.formValue.step1.email = this.$auth.auth0User.email;
		await this.$auth.refreshToken(true);
	}

	async finish(){
		const newCoachProfile = new CoachProfileModel();
		newCoachProfile.firstName = this.formValue.step1.firstName;
		newCoachProfile.lastName = this.formValue.step1.lastName;
		newCoachProfile.email = this.formValue.step1.email;
		newCoachProfile.country = this.formValue.step2.country;
		newCoachProfile.fromOrganization = this.formValue.step2.fromOrganization;
		newCoachProfile.sports = [this.formValue.step3.sport];
		newCoachProfile.pictureUrl = this.formValue.step4.pictureUrl;
		const toProfile = await userStore.addCoachProfile({ coach: newCoachProfile });
		await userStore.acceptTermsOfService(this.formValue.step3.tosAgreed);
		userStore.useCoachProfile({
			profileId: toProfile.id,
		});
		setGAUserProperties({
			is_coach: true,
			onboarding_from_organization: this.formValue.step2.fromOrganization,
		});
		if(navigationStore.HasNavigationQueued){
			return navigationStore.popNavigation();
		}
		this.$router.push('/');
	}
	
	cancelSignup() {
		this.$auth.logout()
	}

	// Only needed when RootOnboarding is used as the frist step
	previous(){
		if (this.currentStep > 2) {
			this.currentStep--;
		}else if(this.currentStep === 2){
			window.history.back();
		}
	}
}
